<template>
  <div @click.stop="close">
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h2 class="modal-title">
              ปรับราคา
              <i style="font-size: 20px" class="bi bi-currency-exchange"></i>
            </h2>
            <button
              data-bs-dismiss="modal"
              @click="close"
              type="button"
              class="btn btn-sm"
              id="closeBtn"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="modal-body">
            <div class="row d-flex justify-content-center text-start mb-4">
              <label class="col-sm-10">รหัสสินค้า</label>
              <div class="col-sm-10">
                <input
                  v-model="form.product_code"
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>
            <div class="row d-flex justify-content-center text-start mb-4">
              <label class="col-sm-10">ชื่อสินค้า</label>
              <div class="col-sm-10">
                <input
                  v-model="form.product_name"
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>

            <div class="row d-flex justify-content-center text-start">
              <div class="col-sm-5">
                <label class="">ราคาทุน</label>
                <input
                  v-model="form.product_cost_price"
                  disabled
                  type="text"
                  class="form-control mb-4"
                  id="inputGroupFile01"
                />
              </div>
              <div class="col-sm-5">
                <label class="">ราคาขายส่ง</label>
                <input
                  v-model="form.product_wholesale_price"
                  type="number"
                  class="form-control mb-4"
                  id="inputGroupFile01"
                />
              </div>
            </div>

            <div class="row d-flex justify-content-center text-start">
              <div class="col-sm-5">
                <label class="">ราคาขายปลีก</label>
                <input
                  v-model="form.product_retail_price"
                  type="number"
                  class="form-control mb-4"
                  id="inputGroupFile01"
                />
              </div>
              <div class="col-sm-5">
                <label class="">ราคา Promotion</label>
                <input
                  v-model="form.product_promotion_price"
                  type="number"
                  class="form-control mb-4"
                  id="inputGroupFile01"
                />
              </div>
            </div>
          </div>

          <hr class="pt-0 mt-0" style="color: LightGrey" />

          <footer class="mt-2 mb-6 ms-7 row d-flex justify-content-center">
            <div
              class="card-toolbar ps-0 col-sm-10 d-flex justify-content-start"
            >
              <button
                @click="submit"
                type="button"
                class="btn btn-primary me-3"
              >
                บันทึก
              </button>
              <button
                @click="close"
                type="button"
                id="closeDialog"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                ยกเลิก
              </button>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

export default {
  props: {
    dialogAddItem: Boolean,
    isEditItem: Boolean,
    dataEditItem: Object,
  },
  data: () => ({
    isSubmit: false,
    isValid: true,

    searchBy: "barcode",
    searchProduct: "",

    form: {
      //new
      selectMainProduct: "",
      selectSubProduct: "",

      product_name: "",
      product_desc: "",
      product_barcode: "",
      product_pog: "",
      product_cost_price: "",
      product_wholesale_price: "",
      product_retail_price: "",
      product_promotion_price: "",
      product_min_stock: "",
      product_max_stock: "",
      product_bring_qty: "",
      status: "0",
      approved_by: "",
      product_expire: "",

      item_amt: "",
      item_discount: "",

      //old
      itemNo: "",
      itemDetail: "",
      barcode: "",
      accountingPeriod: "",
      docNumber: "",
      amount: "",
      pricePerUnit: "",
      discountPerUnit: "",
      vat: "",
      receiveDate: "",
      consignee: "",
      warehouseTo: "",
    },
  }),
  watch: {
    dialogAddItem(val) {
      if (val) {
        this.isValid = true;
        document.getElementById("button-open-dialog").click();
        if (this.dataEditItem) {
          const newData = this.dataEditItem;
          this.form = newData;
          this.searchProduct = this.dataEditItem.product_barcode;
        }
      }
    },
  },
  methods: {
    async submit() {
      this.checkFormIsEmpty();

      this.form = {
        ...this.form,
        isUpdateQty: true,
      };

      const responseUpdate = await whApi.product.update(
        this.form.id,
        this.form
      );
      if (responseUpdate.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ปรับราคาสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          document.getElementById("closeDialog").click();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "ปรับราคาไม่สำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    checkFormIsEmpty() {
      !this.form.item_amt
        ? (this.isValid = false)
        : !this.form.product_code
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    close() {
      this.$emit("closeDialogAddItem");
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
</style>
