<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        ราคาขาย / ป้ายราคา
      </h3>
      <div class="card-toolbar" @mouseenter="hoverSelectSalesman">
        <button
          type="button"
          class="btn btn-sm btn-light-primary btn-hover-scale me-2"
          style="font-size: 14px; font-weight: bold; height: 40px"
          @click="gotoExportFile()"
        >
          <i style="font-size: 16px" class="bi bi-box-arrow-up"></i>
          Export
        </button>

        <div v-if="hoverS">
          <DialogExportFile
            :dialogExportFile="dialogExportFile"
            @closeDialogExportFile="closeDialogExportFile"
            @selectedTypeExportFile="selectedTypeExportFile"
          />
        </div>
      </div>
    </div>
    <div class="card-title row ms-5 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          placeholder="ค้นหา"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-3 mb-3">
        <select v-model="searchBy" class="form-select">
          <option value="1">ตามรหัสสินค้า</option>
          <option value="2">ตาม Barcode</option>
          <option value="3">ตาม POG</option>
        </select>
      </div>
      <div class="col-sm-1 text-end mb-4">
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>

    <div class="card-title row ms-7 me-5">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-2 mt-4">
            <input
              type="checkbox"
              @change="checkAll = !checkAll"
              :checked="checkAll ? true : false"
            />
          </div>
          <div class="col-4">
            <div class="row" @mousedown="hoverExportBtn()">
              <div class="col-6">
                <button
                  @click="printByCheckbox()"
                  class="btn btn-link btn-color-muted btn-active-color-success ms-2"
                >
                  <div
                    :class="
                      !loadingMultiExport
                        ? ''
                        : 'spinner-grow spinner-grow-sm text-light'
                    "
                    role="status"
                  >
                    <i class="bi bi-printer-fill" style="font-size: 16px"></i>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>All</th>
              <th>Item code</th>
              <th>Item name</th>
              <th>Barcode</th>
              <th>POG</th>
              <th>ราคาส่ง</th>
              <th>ราคาปลีก</th>
              <th>ราคา Pro</th>
              <th class="text-center">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="9">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>
                <input
                  type="checkbox"
                  @change="isChecked(item)"
                  :checked="checkAll ? true : false"
                />
              </td>
              <!-- <td>{{ !showing1 ? index + 1 : showing1 + index }}</td> -->
              <td>{{ item.product_code }}</td>
              <td>{{ item.product_name }}</td>
              <td>{{ item.product_barcode }}</td>
              <td>{{ item.product_pog }}</td>
              <td class="text-end">
                {{ formatPrice(item.product_wholesale_price) }}
              </td>
              <td class="text-end">
                {{ formatPrice(item.product_retail_price) }}
              </td>
              <td class="text-end">
                {{
                  item.product_promotion_price
                    ? formatPrice(item.product_promotion_price)
                    : "-"
                }}
              </td>
              <td
                :style="
                  item.is_cancel === '0'
                    ? 'color: #3ca76c; font-weight: bold'
                    : 'color: FireBrick; font-weight: bold'
                "
                class="text-center"
              >
                {{ item.is_cancel === "0" ? "ใช้งาน" : "ยกเลิกใช้งาน" }}
              </td>

              <td>
                <div class="row">
                  <div class="col-sm-6">
                    <button
                      @mouseenter="hoverAddBtn"
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success p-0"
                      @click="addItem(item)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </div>
                  <div class="col-sm-6" @mousedown="hoverExportBtn()">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success p-0"
                      @click="goToExport(item, index)"
                    >
                      <div
                        :class="
                          !loadingExport
                            ? ''
                            : index == indexExport
                            ? 'spinner-grow spinner-grow-sm text-light'
                            : ''
                        "
                        role="status"
                      >
                        <i class="bi bi-printer-fill"></i>
                      </div>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="isExport">
      <pdfFile :dataExport="dataExport" @generatedFile="generatedFile" />
    </div>

    <div v-if="isMultiExport">
      <multiPdfFile
        :multiDataExport="multiDataExport"
        @multiGeneratedFile="multiGeneratedFile"
      />
    </div>

    <div v-if="isExcel">
      <ExportExcelFile :dataExportExcel="dataExportExcel" />
    </div>

    <div v-if="isPdf">
      <ExportPdfFile :dataExportPdf="dataExportPdf" />
    </div>

    <div v-if="hoverA">
      <DialogAdjust
        :dialogAddItem="dialogAddItem"
        :dataEditItem="dataEditItem"
        @submitEditItem="submitEditItem"
        @submitAddItem="submitAddItem"
        @closeDialogAddItem="closeDialogAddItem"
      />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

import Pagination from "./../components/pagination.vue";
import Loader from "./../components/loaderIndex.vue";
import Search from "./../components/search.vue";

import DialogAdjust from "./components/dialogAdjustPrice.vue";

import DialogExportFile from "./components/dialogExportFile.vue";
import ExportExcelFile from "./components/exportFileExcel.vue";
import ExportPdfFile from "./components/exportFilePdf.vue";

import pdfFile from "../components/files/priceTagFile.vue";
import multiPdfFile from "../components/files/multiPriceTagFile.vue";

import numbro from "numbro";

export default {
  components: {
    DialogAdjust,
    DialogExportFile,
    ExportExcelFile,
    ExportPdfFile,
    Pagination,
    Search,
    Loader,
    pdfFile,
    multiPdfFile,
  },
  setup() {
    document.title = "BELUCA | ราคาขาย / ป้ายราคา";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dialogImportExcel: false,
    dialogAddItem: false,

    dialogExportFile: false,

    dataExportExcel: [],
    isExcel: false,

    dataExportPdf: [],
    isPdf: false,

    showing1: null,

    isExport: false,
    loadingExport: false,
    indexExport: "",

    isMultiExport: false,
    loadingMultiExport: false,

    dataItemsShow: [],
    tableItems: [],
    itemApprove: {},

    searchInput: "",
    searchBy: "1",
    allData: [],

    checkbox: [],
    newCheckbox: [],

    checkAll: false,

    hoverS: false,
    hoverA: false,
  }),
  watch: {
    checkAll(val) {
      if (val) {
        this.checkbox = this.dataItemsShow;
        this.newCheckbox = this.dataItemsShow;
      } else {
        this.checkbox = [];
        this.newCheckbox = [];
      }
    },
  },

  created() {
    this.getAll();
  },

  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      try {
        // responseData = await whApi.product.getAll("0");
        responseData = await whApi.product.getAll({
          companyId: companyId,
          companyBranchId: companyBranchId,
        });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataExportExcel = responseData.data;
        this.dataExportPdf = responseData.data;

        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    goToEdit(id) {
      this.$router.push({
        name: "WH4-edit",
        query: {
          id: id,
          editByRecive: true,
        },
      });
    },

    // Export file
    goToExport(item, index) {
      this.dataExport = item;
      this.indexExport = index;
      this.loadingExport = true;
      this.isExport = true;
    },
    generatedFile() {
      this.isExport = false;
    },
    hoverExportBtn() {
      this.loadingExport = false;
      this.isExport = false;
    },
    // Export file

    // Export multi file
    goToMultiExport(item) {
      this.multiDataExport = item;
      this.loadingMultiExport = true;
      this.isMultiExport = true;
    },
    multiGeneratedFile() {
      this.isMultiExport = false;
    },
    // Export multi file

    async search() {
      this.loading = true;
      let responseSearch = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      if (this.searchBy === "1") {
        responseSearch = await whApi.product.search(
          {
            searchByCode: this.searchInput,
          },
          {
            pos: "0",
            companyId: companyId,
            companyBranchId: companyBranchId,
          }
          // "0"
        );
      }

      if (this.searchBy === "2") {
        responseSearch = await whApi.product.search(
          {
            searchByBarcode: this.searchInput,
          },
          {
            pos: "0",
            companyId: companyId,
            companyBranchId: companyBranchId,
          }
          // "0"
        );
      }
      if (this.searchBy === "3") {
        responseSearch = await whApi.product.search(
          {
            searchByPog: this.searchInput,
          },
          {
            pos: "0",
            companyId: companyId,
            companyBranchId: companyBranchId,
          }
          // "0"
        );
      }

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },

    //export file excel, pdf
    gotoExportFile() {
      this.dialogExportFile = true;
      this.isExcel = false;
      this.isPdf = false;
    },

    closeDialogExportFile() {
      this.dialogExportFile = false;
    },

    selectedTypeExportFile(val) {
      this.dialogExportFile = false;
      if (val === "excel") {
        this.isExcel = true;
      } else {
        this.isPdf = true;
      }
    },
    hoverSelectSalesman() {
      this.hoverS = true;
      this.hoverA = false;
    },
    //export file excel, pdf

    // print checkbox
    printByCheckbox() {
      if (this.newCheckbox.length > 0) {
        this.checkbox = this.newCheckbox;
        this.loadingExport1 = true;
        this.goToMultiExport(this.checkbox);
      } else {
        Swal.fire({
          icon: "warning",
          title: `กรุณาเลือกรายการ`,
          confirmButtonText: "ตกลง",
        });
      }
    },
    // print checkbox

    isChecked(val) {
      let findIndex = this.checkbox.indexOf(val);
      if (findIndex !== -1) {
        this.newCheckbox = [...this.checkbox];
        this.newCheckbox.splice(findIndex, 1);
        this.checkbox.splice(findIndex, 1);
      } else {
        this.checkbox.push(val);
        this.newCheckbox = this.checkbox;
      }
    },

    deleteByCheckbox() {
      this.checkbox = this.newCheckbox;
      if (this.checkbox.length) {
        Swal.fire({
          icon: "question",
          text: `ต้องการลบ ${this.checkbox.length} รายการหรือไม่ ?`,
          confirmButtonText: "ตกลง",
          showCancelButton: true,
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            this.showSnackbar = true;
          }
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: `กรุณาเลือกรายการ`,
          confirmButtonText: "ตกลง",
        });
      }
    },
    snackbarConfirmDelete() {
      this.checkbox.forEach((element, index) => {
        this.confirmDelete(element, index);
      });
    },
    closeSnackbar() {
      this.showSnackbar = false;
    },

    // adjust price
    hoverAddBtn() {
      this.hoverA = true;
      this.hoverS = false;
    },
    addItem(item) {
      this.dialogAddItem = true;
      this.dataEditItem = item;
    },
    submitAddItem(item) {
      this.form.products.push(item);
      this.taxTypeChange();
    },
    submitEditItem(item) {
      this.form.products.splice(this.indexEditItem, 1, item);
      this.taxTypeChange();
    },
    closeDialogAddItem() {
      this.dialogAddItem = false;
    },
    goToEditItem(index) {
      this.dataEditItem = this.form.products[index];
      this.indexEditItem = index;
      this.dialogAddItem = true;
    },
    goToDeleteItem(index) {
      Swal.fire({
        icon: "question",
        text: `ต้องการลบรายการที่ ${index + 1} สินค้าใช่หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (index > 0) {
            this.form.products.splice(index, 1);
            this.taxTypeChange();
          } else {
            this.form.products.shift();
            this.taxTypeChange();
          }
        }
      });
    },

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },
  },
};
</script>
