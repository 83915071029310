<template></template>

<script>
import * as XLSX from "xlsx";

import whApi from "@/api/warehouse/";

import dayjs, { Dayjs } from "dayjs";

import numbro from "numbro";

export default {
  name: "app",
  props: {
    dataExportExcel: Object,
  },
  data() {
    return {
      jsonExport: [],

      mainProduct: [],
      subProduct: [],

      // loading: false
    };
  },

  async created() {
    await this.getAllMainPd();
    await this.getAllSubPd();
    await this.groupDateExport();
    // this.onExport();
  },

  methods: {
    groupDateExport() {
      let newMainPd = {};
      let newSubPd = {};

      this.dataExportExcel.forEach((element, index) => {
        newMainPd = this.mainProduct.find(
          (data) => data.id === element.mainProductGroupId
        );

        newSubPd = this.subProduct.find(
          (data) => data.id === element.subProductGroupId
        );

        !newMainPd
          ? ""
          : !newSubPd
          ? ""
          : this.jsonExport.push({
              "#": index + 1,
              รหัสสินค้า: element.product_code,
              ชื่อสินค้า: element.product_name,
              Barcode: element.product_barcode,
              กลุ่มสินค้าหลัก: newMainPd.main_product_group_name,
              กลุ่มสินค้ารอง: newSubPd.sub_product_group_name,
              POG: element.product_pog,
              ราคาทุน: numbro(element.product_cost_price).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              ราคาส่ง: numbro(element.product_wholesale_price).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              ราคาปลีก: numbro(element.product_retail_price).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              ราคาโปร: element.product_promotion_price
                ? numbro(parseFloat(element.product_promotion_price)).format({
                    thousandSeparated: true,
                    mantissa: 2,
                  })
                : "-",
              SOH: element.product_bring_qty,
              "In Transit": element.product_in_transit,
              Mix: element.product_min_stock,
              Max: element.product_max_stock,
              Status: element.is_cancel === "0" ? "ใช้งาน" : "ยกเลิกใช้งาน",
            });
      });

      this.onExport();
    },

    async getAllMainPd() {
      // this.loading = true;
      let responseData = {};
      try {
        responseData = await whApi.mainProduct.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.mainProduct = responseData.data;
        // this.loading = false;
      }
    },

    async getAllSubPd() {
      // this.loading = true;
      let responseData = {};
      try {
        responseData = await whApi.subProduct.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.subProduct = responseData.data;
        // this.loading = false;
      }
    },

    onExport() {
      const date = dayjs(new Date()).format("YYYY-MM-DD-HHMM");

      const dataWS = XLSX.utils.json_to_sheet(this.jsonExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS, "PriceTag1", true);
      XLSX.writeFile(wb, `PriceTag-${date}.xlsx`);
    },
  },
};
</script>
