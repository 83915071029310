<template>
  <div></div>
</template>

<script>
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "../../../assets/build/vfs_fonts";

import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";

import JsBarcode from "jsbarcode/bin/JsBarcode";

import numbro from "numbro";

export default {
  props: {
    multiDataExport: Array,
  },
  data: () => ({
    newProduct: [],
    user: "",
    numberToTh: "",

    productByPrice: [],

    totalItem: 0,
  }),
  async created() {
    await this.getUser();
    await this.exports();
  },

  methods: {
    async getUser() {
      const userId = Decode.decode(localStorage.getItem("boss"));

      let responseData = [];

      try {
        responseData = await userApi.user.getOne(userId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.user = responseData.data;
      }
    },

    textToBase64Barcode(text) {
      let canvas = document.createElement("canvas");
      JsBarcode(canvas, text, { format: "CODE128" });

      return canvas.toDataURL("image/png");
    },

    exports() {
      const newWindow = window.open("", "_blank");

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: "Kanit-Regular.ttf",
          bold: "Kanit-Medium.ttf",
          italics: "Kanit-Medium.ttf",
          bolditalics: "Kanit-Medium.ttf",
        },
      };

      let html = [];

      this.multiDataExport.forEach((element, index) => {
        if (index === this.multiDataExport.length - 1) {
          html.push(
            {
              text: `${
                element.product_name.length > 21
                  ? element.product_name.substring(0, 21) + "..."
                  : element.product_name
              }`,
              fontSize: 12,
              bold: true,
            },
            { text: `${element.product_pog}`, fontSize: 7 },
            {
              table: {
                widths: ["20%", "80%"],
                body: element.product_promotion_price
                  ? [
                      [
                        {
                          text: ``,
                          margin: [-5, -7, 0, 0],
                          fontSize: 7,
                          border: [false, false, false, false],
                        },
                        {
                          text: numbro(element.product_promotion_price).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          alignment: "right",
                          margin: [0, -15, 0, 0],
                          fontSize: 18,
                          bold: true,
                          border: [false, false, false, false],
                        },
                      ],
                      [
                        {
                          text: ``,
                          margin: [-5, -5, 0, 0],
                          fontSize: 7,
                          border: [false, false, false, false],
                        },
                        {
                          text: numbro(element.product_retail_price).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          alignment: "right",
                          margin: [-5, -12, 0, 0],
                          fontSize: 8,
                          border: [false, false, false, false],
                          decoration: "lineThrough",
                        },
                      ],
                      [
                        {
                          text: "",
                          margin: [-5, 20, 0, 0],
                          fontSize: 7,
                          border: [false, false, false, false],
                        },
                        {
                          rowSpan: 2,
                          image: this.textToBase64Barcode(
                            `${element.product_barcode}`
                          ),
                          width: 85,
                          height: 30,
                          alignment: "right",
                          border: [false, false, false, false],
                          margin: [0, -3, -2, 0],
                        },
                      ],
                      [
                        {
                          text: `${element.product_code}`,
                          border: [false, false, false, false],
                          fontSize: 7,
                          margin: [-5, -10, 0, 0],
                        },
                        {
                          text: "",
                          border: [false, false, false, false],
                          fontSize: 7,
                        },
                      ],
                    ]
                  : [
                      [
                        {
                          text: ``,
                          margin: [-5, -7, 0, 0],
                          fontSize: 7,
                          border: [false, false, false, false],
                        },
                        {
                          text: numbro(element.product_retail_price).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          alignment: "right",
                          margin: [0, -5, 0, 0],
                          fontSize: 18,
                          bold: true,
                          border: [false, false, false, false],
                        },
                      ],
                      [
                        {
                          text: "",
                          margin: [-5, 20, 0, 0],
                          fontSize: 7,
                          border: [false, false, false, false],
                        },
                        {
                          rowSpan: 2,
                          image: this.textToBase64Barcode(
                            `${element.product_barcode}`
                          ),
                          width: 90,
                          height: 30,
                          alignment: "right",
                          border: [false, false, false, false],
                          margin: [0, 5, -2, 0],
                        },
                      ],
                      [
                        {
                          text: `${element.product_code}`,
                          border: [false, false, false, false],
                          fontSize: 7,
                          margin: [-5, 0, 0, 0],
                        },
                        {
                          text: "",
                          border: [false, false, false, false],
                          fontSize: 7,
                        },
                      ],
                    ],
              },
            }
          );
        } else {
          html.push(
            {
              text: `${
                element.product_name.length > 21
                  ? element.product_name.substring(0, 21) + "..."
                  : element.product_name
              }`,
              fontSize: 12,
              bold: true,
            },
            { text: `${element.product_pog}`, fontSize: 7 },
            {
              table: {
                widths: ["20%", "80%"],
                body: element.product_promotion_price
                  ? [
                      [
                        {
                          text: ``,
                          margin: [-5, -7, 0, 0],
                          fontSize: 7,
                          border: [false, false, false, false],
                        },
                        {
                          text: numbro(element.product_promotion_price).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          alignment: "right",
                          margin: [0, -15, 0, 0],
                          fontSize: 18,
                          bold: true,
                          border: [false, false, false, false],
                        },
                      ],
                      [
                        {
                          text: ``,
                          margin: [-5, -5, 0, 0],
                          fontSize: 7,
                          border: [false, false, false, false],
                        },
                        {
                          text: numbro(element.product_retail_price).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          alignment: "right",
                          margin: [-5, -12, 0, 0],
                          fontSize: 8,
                          border: [false, false, false, false],
                          decoration: "lineThrough",
                        },
                      ],
                      [
                        {
                          text: "",
                          margin: [-5, 20, 0, 0],
                          fontSize: 7,
                          border: [false, false, false, false],
                        },
                        {
                          rowSpan: 2,
                          image: this.textToBase64Barcode(
                            `${element.product_barcode}`
                          ),
                          width: 85,
                          height: 30,
                          alignment: "right",
                          border: [false, false, false, false],
                          margin: [0, -3, -2, 0],
                        },
                      ],
                      [
                        {
                          text: `${element.product_code}`,
                          border: [false, false, false, false],
                          fontSize: 7,
                          margin: [-5, -10, 0, 0],
                        },
                        {
                          text: "",
                          border: [false, false, false, false],
                          fontSize: 7,
                        },
                      ],
                    ]
                  : [
                      [
                        {
                          text: ``,
                          margin: [-5, -7, 0, 0],
                          fontSize: 7,
                          border: [false, false, false, false],
                        },
                        {
                          text: numbro(element.product_retail_price).format({
                            thousandSeparated: true,
                            mantissa: 2,
                          }),
                          alignment: "right",
                          margin: [0, -5, 0, 0],
                          fontSize: 18,
                          bold: true,
                          border: [false, false, false, false],
                        },
                      ],
                      [
                        {
                          text: "",
                          margin: [-5, 20, 0, 0],
                          fontSize: 7,
                          border: [false, false, false, false],
                        },
                        {
                          rowSpan: 2,
                          image: this.textToBase64Barcode(
                            `${element.product_barcode}`
                          ),
                          width: 90,
                          height: 30,
                          alignment: "right",
                          border: [false, false, false, false],
                          margin: [0, 5, -2, 0],
                        },
                      ],
                      [
                        {
                          text: `${element.product_code}`,
                          border: [false, false, false, false],
                          fontSize: 7,
                          margin: [-5, 0, 0, 0],
                        },
                        {
                          text: "",
                          border: [false, false, false, false],
                          fontSize: 7,
                        },
                      ],
                    ],
              },
              pageBreak: "after",
            }
          );
        }
      });

      const pdfData = {
        pageMargins: [5, 5, 0, 5],
        pageSize: {
          width: 142,
          height: 97.5,
        },
        content: html,
      };
      pdfMake.createPdf(pdfData).open({}, newWindow);
      this.$emit("multiGeneratedFile");
    },
  },
};
</script>
